import { supplierPositionService, supplierRoleService } from '@/api';
import { DialogMixin } from '@/mixins/dialog';
import { SupplierPositionResource, SupplierRoleResource } from '@/resource/model';
import { messageError, translation } from '@/utils';
import { Form, Message } from 'element-ui';
import { CascaderOption } from 'element-ui/types/cascader-panel';
import { mixins } from 'vue-class-component';
import { Component, Prop, Watch } from 'vue-property-decorator';

@Component({})
export default class AddSupplierPositon extends mixins(DialogMixin) {
  @Prop({
    required: false,
    type: Object,
    default: () => {
      return null;
    }
  })
  public position!: SupplierPositionResource | null;

  @Prop({
    required: false,
    type: Object,
    default: () => {
      return null;
    }
  })
  public parentPosition!: { departmentId: number; id: number } | null;

  @Prop({ required: true, type: Array }) public departments!: Array<CascaderOption>;

  public positionForm: {
    companyId: number;
    parentId: number | null;
    posName: string;
    roleId: number | null;
    depId: number | null;
  } = {
    companyId: 0,
    parentId: null,
    posName: '',
    roleId: null,
    depId: null
  };

  public resourceFormRules = {
    depId: [
      {
        required: true,
        validator: (rule: any, value: number, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('position.selectAffiliationDepartment')));
            return;
          }
          callback();
        },
        trigger: 'change'
      }
    ],
    posName: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('position.inputName')));
            return;
          }
          callback();
        },
        trigger: 'blur'
      },
      {
        validator: (rule: any, value: string, callback: Function): void => {
          supplierPositionService
            .checkPositionName(value, this.positionForm.companyId, this.position?.id)
            .then((isRepeat: boolean) => {
              if (isRepeat) {
                callback(translation('requestError.positionNameRepeat'));
                return;
              }
              callback();
            })
            .catch(error => {
              callback(error);
            });
        },
        trigger: 'blur'
      }
    ],
    roleId: [
      {
        required: true,
        validator: (rule: any, value: number, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('position.selectRole')));
            return;
          }
          callback();
        },
        trigger: 'change'
      }
    ],
    parentId: [
      {
        required: true,
        validator: (rule: any, value: number, callback: Function): void => {
          if (value !== 0 && !value) {
            callback(new Error(translation('position.selectParentPosition')));
            return;
          }
          callback();
        },
        trigger: 'change'
      }
    ]
  };

  public positionOptions: Array<SupplierPositionResource> = [];

  public rolesOptions: Array<SupplierRoleResource> = [];

  private operationType: 'add' | 'edit' = 'add';

  public get departmentOptions(): Array<CascaderOption> {
    return this.departments;
  }

  public dialogOpen(): void {
    this.positionForm.companyId = Number(this.$route.query.companyId);
    this.getRoles();
    if (this.position) {
      this.operationType = 'edit';
      this.title = 'position.editPosition';
      this.getParentPositions(this.position.depId);
      this.$nextTick(() => {
        Object.assign(this.positionForm, this.position);
      });
      return;
    }
    if (this.parentPosition) {
      this.positionForm.parentId = this.parentPosition.id;
      this.positionForm.depId = this.parentPosition.departmentId;
      this.getParentPositions(this.positionForm.depId);
    }
    this.operationType = 'add';
    this.title = 'position.addPosition';
  }

  public dialogClosed(): void {
    this.$emit('dialog-closed');
    (this.$refs.positionForm as Form).resetFields();
  }

  public onSubmit(): void {
    (this.$refs.positionForm as Form).validate(async (valid: boolean) => {
      if (!valid) {
        return;
      }
      this.setLoading(true);
      try {
        if (this.operationType === 'add') {
          await supplierPositionService.post({ ...this.positionForm } as any);
          Message.success(translation('operationRes.addSuccess'));
          this.$emit('add-success');
          this.closeDialog();
          return;
        }
        await supplierPositionService.put({ ...this.positionForm } as any);

        (this.positionForm as SupplierPositionResource).parentName =
          this.positionOptions.find(x => x.id === this.positionForm.parentId)?.posName ?? '';

        (this.positionForm as SupplierPositionResource).depName = this.recursionGetSelectedDepName(
          this.positionForm.depId!,
          this.departmentOptions
        );
        Message.success(translation('operationRes.editSuccess'));
        this.$emit('edit-success', this.positionForm);
        this.closeDialog();
      } catch (error) {
        messageError(error);
      } finally {
        this.setLoading(false);
      }
    });
  }

  public departmentChanged(departmentId: number): void {
    if (departmentId) {
      this.getParentPositions(departmentId);
    }
    this.positionForm.parentId = null;
  }

  private async getParentPositions(departmentId: number): Promise<void> {
    try {
      this.positionOptions = (await supplierPositionService.getPositionsByDepartment(departmentId)) || [];
      if (this.operationType === 'edit') {
        // 如果是修改，还需要从岗位选项中去除自己
        this.positionOptions = this.positionOptions.filter(x => x.id !== this.position!.id);
      }

      this.positionOptions.push({
        posName: translation('position.topLevel'),
        id: 0
      } as SupplierPositionResource);
    } catch (error) {
      messageError(error);
    }
  }

  @Watch('positionOptions')
  private handleDefaultPosition(positionOptions: Array<SupplierPositionResource>): void {
    if (positionOptions.length === 0) {
      this.positionOptions = [
        {
          posName: translation('position.topLevel'),
          id: 0
        } as SupplierPositionResource
      ];
    }
  }

  private async getRoles(): Promise<void> {
    try {
      this.rolesOptions = await supplierRoleService.getAllSupplierRoles(this.positionForm.companyId);
    } catch (error) {
      messageError(error);
    }
  }

  /**
   * 递归获取选中的部门名称
   */
  private recursionGetSelectedDepName(departmentId: number, treeOptions: Array<CascaderOption>): string {
    let value = '';
    for (const item of treeOptions) {
      if (item.value === departmentId) {
        value = item.label;
        break;
      }
      if (item.children instanceof Array && item.children.length > 0) {
        const text = this.recursionGetSelectedDepName(departmentId, item.children);
        if (text) return text;
      }
    }
    return value;
  }
}
